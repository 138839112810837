.otpInputBox input{
    font-size: 18px;
    width: 4em !important;
    height: 4em !important;
    text-align: center;
}

.otp-form div{
    display: flex;
    justify-content: center !important;
}

.form-signin{
    width: 30%;
}

.account-otp-wall{
    padding: 20px 20px 10px;
}

.display-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 661px;
}