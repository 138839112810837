.text-red{
    color: red;
}
.react-datepicker {
    font-size: 1.1rem !important;
}
.react-datepicker-wrapper{
    width: -webkit-fill-available;
}

.react-datepicker-popper{
    z-index: 9999 !important;
}
  
.react-datepicker__current-month {
    font-size: 1.5rem !important;
    text-align: center !important;
}
  
.react-datepicker__header {
    padding-top: 6px !important;
}

.react-datepicker__header__dropdown--select{
    text-align: center !important;
}

.react-datepicker__input-container input{
    width: inherit !important;
}
  
.react-datepicker__navigation {
    top: 13px !important;
}
  
.react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
}

.mt-4{
    padding-top: 8px;
    padding-bottom: 8px;
}

.topbar .header-right .header-menu #user-header .dropdown-menu li a i{
    position: relative;
}

.switch{
    margin-top: unset;
}
.btn.btn-lg:hover{
    color: #000;
}
.rc-checkbox-checked .rc-checkbox-inner{
    border-color: #ef4a23 !important;
    background-color: #ef4a23 !important;
}
.rc-checkbox-inner{
    width: 20px !important;
    height: 20px !important;
}
.rc-checkbox .rc-checkbox-inner:after{
    left: 6px !important;
    top: 0px !important;
    width: 6px !important;
    height: 13px !important;
}
.multiSelectContainer .searchWrapper .chip{
    background: rgb(239, 74, 35);
}
.multiSelectContainer .optionListContainer .optionContainer .option:hover{
    background: rgb(239, 74, 35);
}
.datatable-search .input-group-addon{
    background-color: #FF4500;
    color: #fff;
}
.datatable-search input{
    min-width: 250px !important;
}
.pointer{
    -webkit-appearance: auto !important;
}

.rdt_TableCell .btn{
    margin-bottom: unset !important;
}
.enable-scroll{
    height: 75%;
    overflow-y: auto;
}
.text-report-primary {
    color: #FF4500 !important;
    font-weight: bold;
    font-size: 21px;
}
.review-comment{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
}
.admin-response{
    margin-left: 30px;
    padding-left: 15px;
    border-left: 1px solid #ccc;
}
.admin-response-no-border{
    margin-left: 30px;
    padding-left: 15px;
}
.progressbar-container{
    background-color: #ffc8b3;
}
.progressbar-progress{
    height: 8px !important;
}
.location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  border-radius: 0 0 2px 2px;
}

.hover-options:hover {
    background-color: #FF4500 !important;
    cursor: pointer;
    color: #FFF;
}

p {
  margin: 0 0 0px;
}

a {
    cursor: pointer;
}